import { useRoute } from 'vue-router';
import { PartnerProviderType } from '../types/Customer-Dashboard/partner';
import { computed, inject, ref, watch } from 'vue';

export const detailPagesCP = [
  'LocationDetail_CP',
  'VisitDetail_CP',
  'QualificationDetail_CP',
  'OrderDetail_CP',
  'ProcurementDetail_CP',
  'CancellationDetail_CP',
  'DataUsageRequests_CP',
  'DataUsageDetails_CP',
  'TicketDetail_CP',
  'Profile',
  'ReportList_CP',
  'ProfilePassword',
  'ProfileSetting',
  'MonitoringDeviceDetail_CP',
  'MonitoringLocationDetail_CP',
  'CustomerReportRunResult_CP'
];

export const usePartner = () => {
  const route = useRoute();

  if (route.fullPath.startsWith('/staff')) {
    return {};
  }

  const { selectedPartner, is_partner } = inject('partner') as PartnerProviderType;

  const columnIndex = ref<0 | 1>(0);

  const showCustomerInDataTable = computed(
    () =>
      [0, null, undefined].includes(selectedPartner.value) &&
      is_partner &&
      !detailPagesCP.includes(route.name as string)
  );

  const customerIsRequired = computed(() => [0, null, undefined].includes(selectedPartner.value) && is_partner);

  const partnerId = computed(() => {
    if ([0, null, undefined].includes(selectedPartner.value) && is_partner) {
      return selectedPartner.value;
    } else {
      return undefined;
    }
  });

  const loadCustomerColumn = (shouldShow: boolean) => {
    columnIndex.value = shouldShow ? 0 : 1;
  };

  loadCustomerColumn(showCustomerInDataTable.value && is_partner);

  watch(showCustomerInDataTable, (newValue) => {
    loadCustomerColumn(newValue);
  });

  return {
    selectedPartner,
    is_partner,
    showCustomerInDataTable,
    columnIndex,
    detailPagesCP,
    partnerId,
    customerIsRequired
  };
};
