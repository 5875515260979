<template>
  <div class="cp-sidebar-mobile" :class="isDark ? 'is-dark-mobile' : 'is-light-mobile'">
    <div>
      <font-awesome-icon :icon="['far', 'magnifying-glass']" @click.stop="openGlobalSearch" />
    </div>
    <div>
      <img :src="isDark ? '/customer/Logo-Dark.svg' : '/customer/Logo.svg'" alt="Logo" />
    </div>
    <div @click="toggleSidebar">
      <font-awesome-icon v-if="!showSideMenu" :icon="['far', 'bars']" />
      <font-awesome-icon v-else class="cp-sidebar-mobile__close-icon" :icon="['far', 'xmark']" />
    </div>
  </div>

  <div
    v-if="showSideMenu"
    class="cp-sidebar"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      !showSideMenu && pageSize <= 967 ? 'fade-in-left' : '',
      isSmallSidebar ? 'small-sidebar' : ''
    ]"
  >
    <div
      class="cp-sidebar__desktop-toggle-menu"
      @click="
        () => {
          isSmallSidebar = !isSmallSidebar;
          emitter!.emit('toggle-large-content', isSmallSidebar);
        }
      "
    >
      <font-awesome-icon v-if="!isSmallSidebar" :icon="['far', 'chevron-left']" />
      <font-awesome-icon v-else :icon="['far', 'chevron-right']" />
    </div>

    <div class="cp-sidebar__desktop-logo">
      <img v-if="!isSmallSidebar" :src="isDark ? '/customer/Logo-Dark.svg' : '/customer/Logo.svg'" alt="Logo" />
      <img v-else src="/customer/Logo-Only.svg" alt="Logo" />
    </div>
    <div class="cp-sidebar__items-holder" @click="openSmallMenu">
      <div
        v-for="(item, index) in sideMenuItems"
        :key="index"
        class="cp-sidebar__menu-item"
        :class="{ 'cp__square-menu-items': isSmallSidebar }"
      >
        <div class="cp-sidebar__menu-item-name" :class="{ 'cp-sidebar__open-with-Child': item.status }">
          <template v-if="item.route">
            <router-link
              :to="{ name: item.route }"
              class="flex align-items-center"
              :class="{ 'active-menu': isInAliases(item.aliases!) }"
              @click="closeOtherMenu(index)"
            >
              <font-awesome-icon :icon="['far', item.icon]" class="cp__menu-items" />
              <span>{{ item.name }}</span></router-link
            >
          </template>
          <template v-else>
            <p
              class="flex align-items-center cursor-pointer"
              @click="
                () => {
                  toggleMenuChildren(index);
                  isSmallSidebar = false;
                  emitter!.emit('toggle-large-content', isSmallSidebar);
                }
              "
            >
              <font-awesome-icon :icon="['far', item.icon]" class="cp__menu-items" />
              <span>{{ item.name }}</span>
            </p>
            <div>
              <font-awesome-icon
                v-if="item.children && item.status"
                class="absolute cp-sidebar__toggle-child child__position w-1 cursor-pointer cp-sidebar__side-menu-icon-close text-sm"
                icon="chevron-up"
                @click="toggleMenuChildren(index)"
              />
              <font-awesome-icon
                v-else-if="item.children && !item.status"
                class="absolute cp-sidebar__toggle-child child__position w-1 cursor-pointer cp-sidebar__side-menu-icon-open text-sm"
                icon="chevron-down"
                @click="toggleMenuChildren(index)"
              />
            </div>
          </template>
        </div>
        <div v-show="item.status" class="cp-sidebar__menu-item-children">
          <router-link
            v-for="itemChild in item.children"
            :key="itemChild.route"
            :to="{ name: itemChild.route }"
            :class="{ 'active-menu': currentRoute === itemChild.route || isInAliases(itemChild.aliases!) }"
            @click="() => closeSideMenuOnRouteChange()"
            @mouseup="closeOtherMenu(index)"
          >
            <div
              v-if="currentRoute === itemChild.route || isInAliases(itemChild.aliases!)"
              class="cp-sidebar__menu-item-child-before"
            ></div>
            <span
              :style="[currentRoute === itemChild.route || isInAliases(itemChild.aliases!) ? 'margin-left: 3px' : '']"
              >{{ itemChild.name }}</span
            >
            <font-awesome-icon
              v-if="currentRoute === itemChild.route || isInAliases(itemChild.aliases!)"
              class="cp-sidebar__menu-item-child-before-tick"
              :icon="['fas', 'check']"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="cp-sidebar__divider"></div>
    <div
      id="cp-sidebar__user"
      class="cp-sidebar__user"
      :class="{ 'active-profile': isInProfile }"
      aria-haspopup="true"
      aria-controls="userMenuAction"
      @click="toggleUserActions"
    >
      <ImagePhoto
        class="cp-sidebar__user-avatar"
        :src="userAvatar"
        :alt="userName"
        customer
        height="2.5rem"
        width="2.5rem"
        shape="circle"
      />
      <div>
        <p class="cp-sidebar__user-company">{{ userCompany }}</p>
        <p class="cp-sidebar__user-name">{{ userName }}</p>
      </div>
      <span class="cp-sidebar__user-icon"><font-awesome-icon :icon="['far', 'chevron-right']" /></span>
    </div>
  </div>
  <div v-if="showSideMenu" class="blur-page"></div>

  <TieredMenu
    id="userMenuAction"
    ref="userMenuAction"
    class="cp-profile-menu"
    :class="[isDark ? 'is-dark' : 'is-light']"
    :model="is_admin ? adminMenuItems : userMenuItems"
    :popup="true"
  />
</template>

<script setup lang="ts">
import { onBeforeMount, ref, getCurrentInstance, reactive, watch, computed, onUnmounted } from 'vue';
import userAvatarImage from '../../assets/Customer-Dashboard/user-avatar.png';
import { logout } from '../../utilities/logout';
import { sideMenuItem } from '../../types/Staff-Dashboard/SideMenu';
import { useRoute, useRouter } from 'vue-router';
import useEmitter from '../../composables/useEmitter';
import TieredMenu from 'primevue/tieredmenu';
import { LocalStorageUserType } from '../../types/Global';
import ImagePhoto from '../../components/Multipurpose/ImagePhoto.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const emitter = useEmitter();
const isDark = ref(getCurrentInstance()?.appContext.config.globalProperties.$isDark);
const userName = ref<string>('Customer');
const userCompany = ref<string>('Profile');
const userAvatar = ref<string>('');
const userMenuAction = ref<InstanceType<typeof TieredMenu>>();
const User = JSON.parse(localStorage.getItem('user') as string) as LocalStorageUserType;
const is_admin = User.is_admin === 1;
const sideMenuItems = ref<sideMenuItem[]>([
  {
    name: 'Home',
    route: 'DashboardCustomer',
    aliases: [],
    icon: 'house-blank',
    children: []
  },
  {
    name: 'Locations',
    route: 'LocationList_CP',
    aliases: [],
    icon: 'map-marker-alt',
    children: []
  },
  {
    name: 'Inventory',
    route: 'InventoryList_CP',
    aliases: ['qualification-list'],
    icon: 'boxes-stacked',
    children: []
  },
  {
    name: 'Field Services',
    aliases: [],
    icon: 'file-circle-check',
    children: [
      { name: 'Calendar', route: 'Calendar_CP', aliases: [] },
      { name: 'Visits', route: 'VisitList_CP', aliases: [] },
      { name: 'Request Visit', route: 'RequestVisit_CP', aliases: [] }
    ]
  },
  {
    name: 'Procurement',
    aliases: [],
    icon: 'box',
    children: [
      { name: 'Qualifications', route: 'QualificationList_CP', aliases: [] },
      { name: 'Orders', route: 'OrderList_CP', aliases: [] },
      { name: 'MISC Services', route: 'ProcurementList_CP', aliases: [] },
      { name: 'Cancellations', route: 'CancellationList_CP', aliases: [] }
    ]
  },
  {
    name: 'Monitoring',
    route: 'Monitoring_CP',
    aliases: [],
    icon: 'magnifying-glass-chart',
    children: []
  },
  {
    name: 'Data Usage',
    aliases: [],
    icon: 'folders',
    children: [
      { name: 'Requests', route: 'DataUsageRequestsListCustomer', aliases: [] },
      { name: 'Current Usage', route: 'DataUsage_CP', aliases: [] },
      { name: 'History', route: 'DataUsageHistory_CP', aliases: [] }
    ]
  },
  {
    name: 'Support Ticket',
    route: 'TicketList_CP',
    aliases: [],
    icon: 'file-lines',
    children: []
  },
  {
    name: 'Report',
    icon: 'memo',
    route: 'CustomerReportList_CP',
    aliases: [],
    children: []
  }
]);
const userMenuItems = reactive([
  {
    label: 'Profile',
    icon: 'pi pi-user',
    to: {
      name: 'Profile'
    },
    command: () => {
      closeSideMenuOnRouteChange('Profile');
    }
  },
  {
    label: 'Change Password',
    icon: 'pi pi-lock',
    to: {
      name: 'ProfilePassword'
    },
    command: () => {
      closeSideMenuOnRouteChange('ProfilePassword');
    }
  },
  {
    label: 'User Management',
    icon: 'pi pi-user',
    to: {
      name: 'UserManagement_CP'
    },
    command: () => {
      closeSideMenuOnRouteChange('UserManagement_CP');
    }
  },
  {
    label: 'Settings',
    icon: 'pi pi-cog',
    to: {
      name: 'ProfileSetting'
    },
    command: () => {
      closeSideMenuOnRouteChange('ProfileSetting');
    }
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout(false).catch(() => {});
    }
  }
]);
const adminMenuItems = reactive([
  {
    label: 'Profile',
    icon: 'pi pi-user',
    to: {
      name: 'Profile'
    },
    command: () => {
      closeSideMenuOnRouteChange('Profile');
    }
  },
  {
    label: 'Change Password',
    icon: 'pi pi-lock',
    to: {
      name: 'ProfilePassword'
    },
    command: () => {
      closeSideMenuOnRouteChange('ProfilePassword');
    }
  },
  {
    label: 'User Management',
    icon: 'pi pi-user',
    to: {
      name: 'UserManagement_CP'
    },
    command: () => {
      closeSideMenuOnRouteChange('UserManagement_CP');
    }
  },
  {
    label: 'Token Management',
    icon: 'pi pi-key',
    to: {
      name: 'TokenManagement_CP'
    },
    command: () => {
      closeSideMenuOnRouteChange('TokenManagement_CP');
    }
  },
  {
    label: 'Settings',
    icon: 'pi pi-cog',
    to: {
      name: 'ProfileSetting'
    },
    command: () => {
      closeSideMenuOnRouteChange('ProfileSetting');
    }
  },

  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout(false).catch(() => {});
    }
  }
]);
const pageSize = ref(0);
const showSideMenu = ref(false);
const isSmallSidebar = ref(false);
const router = useRouter();
const route = useRoute();
const currentRoute = ref(router.currentRoute.value.name);
const isInProfile = computed(() => {
  if (['Profile', 'ProfilePassword', 'ProfileSetting'].includes(route.name?.toString() || '')) {
    return true;
  }
  return false;
});

const openGlobalSearch = () => {
  emitter!.emit('open-global-search');
};

const toggleUserActions = (event: Event) => {
  userMenuAction.value?.toggle(event);
};

function toggleMenuChildren(index: number) {
  if (!sideMenuItems.value[index].children && sideMenuItems.value[index].route) {
    void router.push({ name: sideMenuItems.value[index].route as string });
    closeSideMenuOnRouteChange();
  } else {
    sideMenuItems.value[index].status = !sideMenuItems.value[index].status;
  }
}

const closeOtherMenu = (index: number) => {
  closeSideMenuOnRouteChange();
  sideMenuItems.value.forEach((item, itemIndex) => {
    if (itemIndex !== index) {
      item.status = false;
    }
  });
};

const toggleSidebar = () => {
  showSideMenu.value = !showSideMenu.value;
};

const isInAliases = (aliases: Array<string>) => {
  let Status = false;
  aliases.forEach((item) => {
    Status = item.toString() === currentRoute.value?.toString();
  });
  return Status;
};

const closeSideMenuOnRouteChange = (action?: string) => {
  if (action) {
    void router.push({ name: action });
  }
  isSmallSidebar.value = false;
  emitter!.emit('toggle-large-content', isSmallSidebar.value);

  if (pageSize.value <= 967) {
    showSideMenu.value = false;
  } else if (pageSize.value > 967 && showSideMenu.value == false) {
    showSideMenu.value = true;
  }
};
watch(
  () => router.currentRoute.value.path,
  () => {
    currentRoute.value = router.currentRoute.value.name;
  }
);

watch(pageSize, () => {
  closeSideMenuOnRouteChange();
});

const updateSize = () => {
  pageSize.value = window.innerWidth;
};

const openSmallMenu = () => {
  if (isSmallSidebar.value) {
    isSmallSidebar.value = !isSmallSidebar.value;
  }
};

onBeforeMount(() => {
  updateSize();
  window.addEventListener('resize', updateSize);

  const getUser = JSON.parse(localStorage.getItem('user') as string) as LocalStorageUserType;
  if (getUser && getUser.name) userName.value = getUser.name;
  if (getUser && getUser.company) userCompany.value = getUser.company;
  if (getUser && getUser.avatar) userAvatar.value = getUser.avatar;
  else userAvatar.value = userAvatarImage;
});

onUnmounted(() => {
  window.removeEventListener('resize', updateSize);
});
</script>

<style scoped lang="scss">
.active-menu {
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.cp-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 287px;
  padding: 24px;
  z-index: 100;

  &__desktop-toggle-menu {
    position: absolute;
    right: -18px;
    z-index: 999;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
  }

  &__user {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    padding: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  &__user-avatar {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 11px;
  }

  &__user-company {
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
  }

  &__user-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &__user-icon {
    position: absolute;
    right: 11px;
    color: #757575;
    font-size: 13px;
  }

  &__desktop-logo {
    img {
      // width: 116px;
      height: 40px;
    }
  }

  :deep(.cp-sidebar__user-avatar) {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 10px;
  }

  &__items-holder {
    margin-top: 48px;
    max-height: calc(100vh - 260px);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &__menu-item {
    margin-bottom: 6px;

    a,
    p {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    a {
      display: block;
    }

    p {
      display: inline-block;
      margin: 0;
    }

    svg {
      width: 24px;
      height: 24px;
      // margin-left: -12px;
    }
  }

  &__menu-item-name {
    position: relative;
    // padding: 13px 12px;
    border-radius: 8px;
  }

  &__menu-item-children {
    padding: 8px 0 8px 6px;
    border-radius: 0 0 4px 4px;

    a {
      display: list-item;
      list-style-position: inside;
      font-weight: normal;
      font-size: 16px;
      line-height: 33px;
      letter-spacing: 0.5px;
      padding-left: 10px;
    }
  }

  &__toggle-child {
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 16px;

    svg {
      width: unset;
    }
  }

  &__divider {
    display: block;
    height: 1px;
    position: absolute;
    bottom: 115px;
    width: calc(100% - 48px);
  }

  &__open-with-Child {
    border-radius: 4px 4px 0 0;
  }

  &__menu-item-child-before {
    width: 6px;
    height: 32px;
    border-radius: 0 12px 12px 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: inline-block;
    margin-left: -36px;
    margin-right: 26px;
    vertical-align: middle;
  }

  &__menu-item-child-before-tick {
    margin-left: unset !important;
    float: right;
    margin-right: 15px;
    font-size: 14px;
    margin-top: 5px;
    width: 18px !important;
    height: 18px !important;
  }
}

.cp-sidebar-mobile {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98;
  height: 64px;
  padding: 0 16px;

  img {
    width: 116px;
    height: 40px;
  }

  :deep(.cp-sidebar__user-avatar) {
    width: 40px;
    height: 40px;
  }

  svg {
    font-size: 20px;
  }
}

.is-light {
  background: white;
  border-right: 1px solid #eeeeee;

  .cp-sidebar {
    &__side-menu-icon-close,
    &__side-menu-icon-open {
      color: #757575;
    }
  }

  .cp-sidebar__desktop-toggle-menu {
    background: white;
    color: #757575;
    border: 1px solid #eeeeee;
  }

  .cp-sidebar__user {
    border: 1px solid #eeeeee;
  }

  :deep(.cp-sidebar__user-avatar) {
    border: 1px solid #eeeeee;
  }

  .cp-sidebar__user-company {
    color: #999797;
  }

  .cp-sidebar__user-name {
    color: #212121;
  }

  .cp-sidebar__divider {
    background: #eeeeee;
  }

  .cp-sidebar__open-with-Child {
    background: #6e9dc5;

    &:hover {
      background: #6e9dc5 !important;
      color: #757575;
    }

    p,
    svg {
      color: white !important;
    }
  }

  .cp-sidebar__menu-item-name {
    &:hover {
      background: #fafafa;
      color: #757575;

      a,
      p,
      svg {
        color: #757575;
      }
    }
  }

  .cp-sidebar__menu-item-name:has(.router-link-exact-active) {
    background: #f4b73f !important;

    a,
    svg {
      color: white !important;
    }
  }

  .cp-sidebar__menu-item {
    a,
    p {
      color: #757575;

      &:hover {
        color: #757575;
      }
    }

    .cp-sidebar__menu-item-children {
      background: #eff6fc;

      a {
        color: #999797;

        &:hover {
          color: #5b5b5b;
        }
      }
    }
  }

  .active-menu {
    color: #296caa !important;
  }

  .cp-sidebar__menu-item-child-before {
    background: #296caa;
  }

  .cp-sidebar__user:hover {
    border: 1px solid #e0e0e0;
    background: #fafafa;
  }

  .active-profile {
    border-radius: 5px;
    border: 1px solid #f4b73f;
    background: #fefbf4;
  }
}

.is-dark {
  background: #212121;
  border-right: 1px solid #3b3b3b;

  .cp-sidebar {
    &__side-menu-icon-close,
    &__side-menu-icon-open {
      color: #bdbdbd;
    }
  }

  .cp-sidebar__desktop-toggle-menu {
    background: #212121;
    color: #bdbdbd;
    border: 1px solid #3b3b3b;
  }

  .cp-sidebar__user {
    border: 1px solid #3b3b3b;
  }

  :deep(.cp-sidebar__user-avatar) {
    border: 1px solid #3b3b3b;
  }

  .cp-sidebar__user-company {
    color: #999797;
  }

  .cp-sidebar__user-name {
    color: white;
  }

  .cp-sidebar__divider {
    background: #3b3b3b;
  }

  .cp-sidebar__menu-item-name {
    &:hover {
      background: #3b3b3b !important;

      a,
      p,
      svg {
        color: #bdbdbd;
      }
    }
  }

  .cp-sidebar__menu-item-name:has(.router-link-exact-active) {
    background: #f4b73f !important;

    a,
    p,
    svg {
      color: #121212 !important;
    }
  }

  .cp-sidebar__open-with-Child {
    background: #296caa;

    &:hover {
      background: #296caa !important;
    }

    p,
    svg {
      color: #121212 !important;
    }
  }

  .cp-sidebar__menu-item {
    a,
    p {
      color: #bdbdbd;

      &:hover {
        color: #bdbdbd;
      }
    }
  }

  .cp-sidebar__menu-item-children {
    background: #071522;

    a {
      color: #999797;

      &:hover {
        color: #e0e0e0;
      }
    }
  }

  .active-menu {
    color: #6e9dc5 !important;
  }

  .cp-sidebar__menu-item-child-before {
    background: #6e9dc5;
  }

  .cp-sidebar__user:hover {
    border: 1px solid #525252;
    background: #3b3b3b;
  }

  .active-profile {
    border-radius: 5px;
    border: 1px solid #f4b73f;
    background: #1e1607;
  }
}

:global(.is-light .p-menuitem-link:hover) {
  color: #3b3b3b;
  background: #fafafa;
}

:global(.is-dark .p-menuitem-link:hover) {
  color: #bdbdbd;
  background: #3b3b3b;
}

.is-dark-mobile {
  border-bottom: 1px solid #3b3b3b;
  background: #212121;

  svg {
    color: #999797;
  }

  .cp-sidebar-mobile__close-icon {
    border-radius: 100%;
    padding: 5px 8px;
    background: white;
    color: #112142;
  }
}

.is-light-mobile {
  border-bottom: 1px solid #f5f5f5;
  background: white;

  svg {
    color: #999797;
  }

  .cp-sidebar-mobile__close-icon {
    border-radius: 100%;
    padding: 5px 8px;
    background: #112142;
    color: white;
  }
}

@media screen and (max-width: 967px) {
  .cp-sidebar {
    padding: 16px;
  }
  .cp-sidebar__items-holder {
    margin-top: 22px;
  }
  .cp-sidebar__desktop-toggle-menu {
    display: none;
  }
  .cp-sidebar-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.small-sidebar {
  width: 110px;

  .cp-sidebar__menu-item-name {
    span {
      display: none;
    }
  }

  .cp-sidebar__toggle-child,
  .cp-sidebar__menu-item-children,
  .cp-sidebar__user-icon,
  .cp-sidebar__user-company,
  .cp-sidebar__user-name {
    display: none;
  }

  .cp-sidebar__menu-item svg {
    margin-left: unset;
    // width: 36px;
  }

  .cp-sidebar__desktop-logo {
    text-align: center;

    img {
      // width: 116px;
      height: 40px;
    }

    :deep(.cp-sidebar__user-avatar) {
      // width: 116px;
      height: 40px;
    }
  }

  .cp-sidebar__open-with-Child {
    border-radius: 8px;
  }
}

:global(.child__position) {
  top: 12px !important;
}

@media screen and (min-width: 950px) {
  .blur-page {
    display: none;
  }
  #userMenuAction.p-tieredmenu-overlay {
    top: 400px;
    left: 16px;
    z-index: 1000;
  }
  :global(.child__position) {
    right: 12px !important;
    top: 18px !important;
  }
}

#userMenuAction.p-tieredmenu-overlay {
  bottom: 30px;
  right: 400px;
  z-index: 1000;
}

.blur-page {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 9;
}

.cp__menu-items {
  padding: 13px 12px;
}

.cp-sidebar {
  &__side-menu-icon-close,
  &__side-menu-icon-open {
    height: 15px !important;
  }
}

.cp__square-menu-items {
  width: 50px;
  margin-right: auto;
  margin-left: auto;
}
</style>
