import axios, { AxiosResponse } from 'axios';
import { selectInputOptionSchema } from '../../types/Global';

export default class SelectInputService {
  async getInputOptions(
    typePrimary: string,
    idPrimary?: number,
    additionalId?: string | number,
    additionalIds?: number[]
  ): Promise<AxiosResponse<selectInputOptionSchema[]> | undefined> {
    const isEssenceTypeValid = [
      'ReportParamValues',
      'Customer',
      'CustomerCP',
      'AccountableParty',
      'BBRequestDataFirm',
      'BuildingType',
      'CancellationStatus',
      'ContactRole',
      'CustomerProject',
      'IpAssignment',
      'CustomerLocation',
      'CustomerLocationCP',
      'LocationStatus',
      'Make',
      'Model',
      'ManagementType',
      'OrderCarrierManagedBy',
      'OrderPriority',
      'OrderStages',
      'OrderStatus',
      'OrderType',
      'QualificationProduct',
      'ProjectProcurementTemplate',
      'SimilarProvider',
      'Provider',
      'QualificationServiceType',
      'ServiceType',
      'QualificationSource',
      'QualificationStatus',
      'CarrierManagedBy',
      'Signage',
      'SurveyResult',
      'SurveyStatus',
      'InventoryShippingService',
      'InventoryShippingStatus',
      'InventoryStatus',
      'OrderListByLocation',
      'ProcurementServiceCategories',
      'ProcurementServiceStatuses',
      'CellularRequestTypes',
      'CustomerPlans',
      'PlanDataAllowance',
      'DataUsageDateFilter',
      'DataUsageDevices',
      'Country',
      'CountryStates',
      'TemUsStates',
      'CustomerCountryStates',
      'User',
      'PartnerUser',
      'TemBillType',
      'TemPayType',
      'TemStatus',
      'TemFlag',
      'ServiceProjectManagers',
      'Partner',
      'JobTemplate',
      'JobTemplate_CP',
      'DispatchBillableRateType',
      'DispatchRateType',
      'DispatchPayType',
      'RemoteVisitManagements',
      'RemoteVisitManagementTech',
      'RoutePublishOption',
      'DirectTechTechnician',
      'DispatchTechnician',
      'FieldNationWorkType',
      'DispatchVendor',
      'DispatchCompany',
      'DispatchTimeZone',
      'DynamicLocation',
      'VisitOutcome',
      'TaskFailureReason',
      'TicketStatus',
      'TicketPriority',
      'TicketPriorityCP',
      'TicketType',
      'TicketDepartment',
      'Department',
      'MacroCategory',
      'AuditModels',
      'VisitCancellationParty',
      'VisitCancellationReason',
      'RoleList',
      'CustomerLocationStatus',
      'CustomerCountry',
      'CustomerBuildingType',
      'CommentProvider',
      'AttachmentCategory',
      'CpAttachmentCategory',
      'DispatchTaskCategory',
      'LocationOrders',
      'OrderMissedTargetReasons',
      'CellularBandType',
      'CellularDataCapDetails',
      'TemVendors',
      'DispatchDocumentCategory',
      'DispatchDocumentCategoryCP',
      'jobVisitList',
      'MilestoneTypes',
      'TicketSeverity',
      'TicketList',
      'FstVendors',
      'FstVendorTech',
      'Timezone',
      'ipAssignment',
      'AlertType',
      'DataUsageDateFilterCP',
      'BillFundingAccounts',
      'AgentTypes'
    ].includes(typePrimary);
    if (!isEssenceTypeValid) throw new Error('Essence type is not valid');
    switch (typePrimary) {
      case 'ReportParamValues':
        return await axios.get('/reports/parameter/' + idPrimary);
      case 'Customer':
        return await axios.get('/customers/list');
      case 'CustomerCP':
        return await axios.get('/panel/customer/customers-list');
      case 'AccountableParty':
        return await axios.get('/order/accountable/parties');
      case 'BBRequestDataFirm':
        return await axios.get('/orders/bb-requested-firm-date/list');
      case 'BuildingType':
        return await axios.get('/building-types');
      case 'CancellationStatus':
        return await axios.get('/cancellation/statuses');
      case 'ContactRole':
        return await axios.get('/contacts/roles');
      case 'CustomerProject':
        return await axios.get('/customers/projects/' + idPrimary);
      case 'IpAssignment':
        return await axios.get('/order/ip-assignments');
      case 'CustomerLocation':
        return await axios.get('/customers/' + idPrimary + '/locations');
      case 'LocationStatus':
        return await axios.get('/locations-status');
      case 'Make':
        return await axios.get('/inventory/makes/list');
      case 'Model':
        return await axios.get('/inventory_models/inventory_make_short/' + idPrimary);
      case 'ManagementType':
        return await axios.get('/managementTypes/list');
      case 'OrderCarrierManagedBy':
        return await axios.get('/orders/carrier/managed-by');
      case 'OrderPriority':
        return await axios.get('/order/priorities');
      case 'OrderStage':
        return await axios.get('/order/stages');
      case 'OrderStatus':
        return await axios.get('/order/statuses/' + idPrimary);
      case 'OrderType':
        return await axios.get('/orders/type');
      case 'QualificationProduct':
        return await axios.get('/qualifications/products-list/' + idPrimary);
      case 'ProjectProcurementTemplate':
        return await axios.get('/projects/' + idPrimary + '/procurements');
      case 'SimilarProvider':
        return await axios.get('/qualifications/providers/same?name=' + idPrimary);
      case 'Provider':
        return await axios.get('/providers/list/select-input');
      case 'QualificationServiceType':
        return await axios.get('/qualifications/services/list');
      case 'ServiceType':
        return await axios.get('/qualifications/service-types/list');
      case 'QualificationSource':
        return await axios.get('/qualifications/source/list');
      case 'QualificationStatus':
        return await axios.get('/qualification-statuses');
      case 'CarrierManagedBy':
        return await axios.get('/qualification-template/carrier-managed-by');
      case 'Signage':
        return await axios.get('/signage?customer_id=' + idPrimary);
      case 'SurveyResult':
        return await axios.get('/survey/results');
      case 'SurveyStatus':
        return await axios.get('/survey/status');
      case 'InventoryShippingService':
        return await axios.get('/shipping_service');
      case 'InventoryShippingStatus':
        return await axios.get('/shipping_status');
      case 'InventoryStatus':
        return await axios.get('/inventory_statuses');
      case 'OrderStages':
        return await axios.get('/order/stages');
      case 'ProcurementServiceCategories':
        return await axios.get('/procurement-service/categories');
      case 'ProcurementServiceStatuses':
        return await axios.get('/procurement-service/statuses');
      case 'OrderListByLocation':
        return await axios.get('/orders/locations/' + idPrimary);
      case 'CellularRequestTypes':
        return await axios.get('/cellular-request-types');
      case 'CustomerPlans':
        return await axios.get(`/customers/${idPrimary}/plans`);
      case 'PlanDataAllowance':
        return await axios.get(`/plans/${idPrimary}/data_allowances`);
      case 'DataUsageDateFilter':
        return await axios.get('/sim_usage_histories/date/List');
      case 'DataUsageDateFilterCP':
        return await axios.get('/panel/customer/sim_usage_histories/date/List');
      case 'CustomerLocationCP':
        return await axios.get(`/panel/customer/locations/list/${idPrimary}`);
      case 'DataUsageDevices':
        return await axios.get(`/sim_usages/getDevice/plans/${idPrimary}/${additionalId}`);
      case 'Country':
        return await axios.get(`/countries/available`);
      case 'CountryStates':
        if (!idPrimary) return undefined;
        return await axios.get(`/countries/${idPrimary}/states`);
      case 'TemUsStates':
        return await axios.get('/us-states');
      case 'CustomerCountryStates':
        if (!idPrimary) return undefined;
        return await axios.get(`/panel/customer/countries/${idPrimary}/states`);
      case 'User':
        return await axios.get(`/users/lists/byId`);
      case 'PartnerUser':
        return await axios.get(`/partnerships/${idPrimary}/users/list`);
      case 'TemBillType':
        return await axios.get(`/tem/billType/list`);
      case 'TemPayType':
        return await axios.get(`/tem/payType/list`);
      case 'TemStatus':
        return await axios.get(`/tem/status/list`);
      case 'TemFlag':
        return await axios.get(`/tem/flag/list`);
      case 'ServiceProjectManagers':
        return await axios.get(`/service-project/managers`);
      case 'Partner':
        return await axios.get(`/partners/list`);
      case 'JobTemplate':
        return await axios.get(`/dispatch/job/template/project/${idPrimary}/customer/${additionalId}`);
      case 'JobTemplate_CP':
        return await axios.get(`/panel/customer/visits/template`);
      case 'DispatchRateType':
        return await axios.get(`/sof/services`);
      case 'DispatchBillableRateType':
        return await axios.get(`/dispatch/rateTypes`);
      case 'DispatchPayType':
        return await axios.get(`/dispatch/payTypes`);
      case 'RemoteVisitManagements':
        return await axios.get(`/dispatch/job/remote/visit-management/types`);
      case 'RemoteVisitManagementTech':
        return await axios.get(`/dispatch/turn-up/techs?type=` + idPrimary);
      case 'RoutePublishOption':
        return await axios.get(`/dispatch/publishOptions`);
      case 'FieldNationWorkType':
        return await axios.get(`/dispatch/fieldnation/work-types`);
      case 'DirectTechTechnician':
        return await axios.get(`/dispatch/provider-technicians`);
      case 'DispatchTechnician':
        return await axios.get(`/dispatch/technicians`);
      case 'DispatchVendor':
        return await axios.get(`/dispatch/vendor/list`);
      case 'DispatchCompany':
        return await axios.get(`/dispatch/companies`);
      case 'DispatchTimeZone':
        return await axios.get(`/dispatch/timezones`);
      case 'DynamicLocation':
        return await axios.get(`/dynamicApi/customers/${idPrimary}/locations/list`);
      case 'VisitOutcome':
        return await axios.get(`/dispatch/visits/outcome/values`);
      case 'TaskFailureReason':
        return await axios.get(`/dispatch/task/failureReasons`);
      case 'TicketStatus':
        return await axios.get(`/tickets/statuses`);
      case 'TicketPriority':
        return await axios.get(`/tickets/priorities`);
      case 'TicketPriorityCP':
        return await axios.get(`/panel/customer/tickets/priorities/list`);
      case 'TicketType':
        return await axios.get(`/tickets/types`);
      case 'TicketDepartment':
        return await axios.get(`/tickets/departments`);
      case 'Department':
        return await axios.get(`/departments`);
      case 'MacroCategory':
        return await axios.get(`/macros/categories/${idPrimary}/sub-categories?type=${additionalId}`);
      case 'AuditModels':
        return await axios.get(`/audits/models/list`);
      case 'VisitCancellationParty':
        return await axios.get(`/dispatch/cancellation-parties`);
      case 'VisitCancellationReason':
        return await axios.get(`/dispatch/cancellation-parties/${idPrimary}/reasons`);
      case 'RoleList':
        return await axios.get(`/roles`);
      case 'CustomerLocationStatus':
        return await axios.get(`/panel/customer/locations-status`);
      case 'CustomerCountry':
        return await axios.get(`/panel/customer/countries`);
      case 'CustomerBuildingType':
        return await axios.get(`/panel/customer/building-types`);
      case 'CommentProvider':
        return await axios.get(`/dispatch/${idPrimary}/request/providers`);
      case 'AttachmentCategory':
        return await axios.get(`/attachment/categories`);
      case 'CpAttachmentCategory':
        return await axios.get(`/panel/customer/attachment/categories`);
      case 'DispatchTaskCategory':
        return await axios.get(`/dispatch/tasks/category/list`);
      case 'LocationOrders':
        return await axios.get(`/procurement-services/${idPrimary}/relatedOrders`);
      case 'OrderMissedTargetReasons':
        return await axios.get(`/order/reason-for-missed-target-install-dates`);
      case 'CellularBandType':
        return await axios.get('/cellular-band-types');
      case 'CellularDataCapDetails':
        return await axios.get('/cellular-data-cap-details');
      case 'TemVendors':
        return await axios.get(`/telecom/expenses/vendors/list${idPrimary ? '?vendor=' + idPrimary : ''}`);
      case 'DispatchDocumentCategory':
        return await axios.get('/dispatch/job/document/categories/list');
      case 'DispatchDocumentCategoryCP':
        return await axios.get('/panel/customer/visits/categories/list');
      case 'jobVisitList':
        return await axios.get(`/dispatch/dispatch_jobs/${idPrimary}/visit-list/except/${additionalId}`);
      case 'MilestoneTypes':
        return await axios.get('/tickets/milestones');
      case 'TicketSeverity':
        return await axios.get('/tickets/severities/list');
      case 'TicketList':
        return await axios.post('/tickets/getTickets/list', { ticket_ids: additionalIds });
      case 'FstVendors':
        return await axios.get('/dispatch/fst-vendors/list');
      case 'FstVendorTech':
        return await axios.get(`/dispatch/fst_vendors/${idPrimary}/tech/list`);
      case 'Timezone':
        return await axios.get(`/timezone/list`);
      case 'ipAssignment':
        return await axios.get(`/ipAssignment`);
      case 'AlertType':
        return await axios.get(`/customers/alerts/alertTypes/list`);
      case 'BillFundingAccounts':
        return await axios.get('/tem/bill-funding-accounts');
      case 'AgentTypes':
        return await axios.get('/partnership/types');
    }
  }
}
