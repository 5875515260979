import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';
import { beforeEachStaffDashboard, StaffDashboardRouter } from './Staff-Dashboard';
import { beforeEachCustomerDashboard, CustomerDashboardRouter } from './Customer-Dashboard';
const loadView = (view: string) => {
  return () => import(`../views/${view}.vue`);
};
export const routes = [
  {
    path: '/',
    name: 'Home',
    component: loadView('Login'),
    meta: { title: 'Login', ignoreHistory: true },
    redirect: { name: 'Login' },
    children: []
  },
  { path: '/login', name: 'Login', component: loadView('Login'), meta: { title: 'Login', ignoreHistory: true } },
  ...StaffDashboardRouter,
  ...CustomerDashboardRouter,
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: loadView('ErrorPage404'),
    meta: { title: 'Page not found 404', ignoreHistory: true }
  },
  {
    name: 'Access-Denied',
    path: '/access-denied',
    component: loadView('AccessDenied'),
    meta: { title: 'Access Denied', ignoreHistory: true }
  }
];

const router: Router = createRouter({
  history: createWebHistory(),
  routes: routes
});

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = <string>to.meta.title;

  const loginTypeSelector = window.location.hostname.toLowerCase();

  if (loginTypeSelector.startsWith(import.meta.env.VITE_CUSTOMER_PORTAL_DOMAIN as string)) {
    await beforeEachCustomerDashboard(to, from, next);
  } else if (loginTypeSelector.startsWith(import.meta.env.VITE_STAFF_PORTAL_DOMAIN as string)) {
    await beforeEachStaffDashboard(to, from, next);
  }
});
export default router;
