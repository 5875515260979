import axios, { AxiosResponse } from 'axios';
import {
  CommentsParams,
  newAttachmentSchema,
  CommentSchema,
  ProjectManagerReviewSchema,
  tableParams,
  departmentsResponseSchema,
  GlobalTableParams,
  ProjectManagerReviewSampleSchema,
  attachmentItemSchema,
  BulkExportResponse,
  attachmentCategory,
  attachmentFilter,
  NoteSchema
} from '../../types/Global';
import { normalizeGetTableQuery } from '../../utilities/globalHelpers';
import { GlobalSearchResponse, SearchableModel } from '../../types/Global';
export default class GlobalService {
  async essenceArchive(
    essenceType: string,
    essenceId: number,
    essenceArchiveStatus: boolean
  ): Promise<AxiosResponse | undefined> {
    const isEssenceTypeValid = [
      'qualification',
      'customer',
      'order',
      'procurementService',
      'cellular request'
    ].includes(essenceType);
    if (!isEssenceTypeValid) throw new Error('Essence type is not valid');
    const data = {
      is_archived: essenceArchiveStatus
    };
    switch (essenceType) {
      case 'qualification':
        return await axios.patch('/qualifications/' + essenceId, data);
      case 'customer':
        return await axios.patch('/customers/' + essenceId, data);
      case 'order':
        return await axios.patch('/orders/' + essenceId, data);
      case 'procurementService':
        return await axios.patch('/procurement-services/' + essenceId + '/modify', data);
      case 'cellular request':
        return await axios.patch('/cellular-requests/patch/' + essenceId, data);
    }
  }

  async exportToExcel(
    type: string,
    filters: tableParams,
    essenceId: number | null,
    extraEssenceId: number | null,
    include_is_archived = false,
    query: Record<string, never> = {}
  ): Promise<AxiosResponse<File | null | BulkExportResponse>> {
    const selected_ids: number[] | null = filters.selected_ids ?? null;
    const unselected_ids: number[] | null = filters.unselected_ids ?? null;
    const is_all: boolean | null = filters.is_all ?? null;

    const finalParams = JSON.parse(JSON.stringify(normalizeGetTableQuery(filters))) as GlobalTableParams;
    if (essenceId) finalParams['customer_id'] = essenceId;
    finalParams['selected_ids'] = selected_ids;
    finalParams['unselected_ids'] = unselected_ids;
    finalParams['is_all'] = is_all;
    finalParams['include_is_archived'] = include_is_archived;

    if (query && typeof query === 'object') {
      for (const [key, value] of Object.entries(query)) {
        if (key.startsWith('params[') && key.endsWith(']')) {
          const paramKey = key.slice(7, -1);
          if (!finalParams.params) finalParams.params = {};
          finalParams.params[paramKey] = value;
        } else {
          finalParams[key as keyof GlobalTableParams] = value;
        }
      }
    }

    let url = '';
    if (
      type === 'customers' ||
      type === 'locations' ||
      type === 'qualifications' ||
      type === 'verizon_devices' ||
      type === 'choice-iot-devices' ||
      type === 'cancellation' ||
      type === 'cradlePoints' ||
      type === 'sim_usages' ||
      type === 'sof'
    ) {
      url = '/' + type + '/export';
    } else if (type === 'orders') {
      url = '/' + type + '/export';
      if (extraEssenceId) {
        url += '?location_id=' + extraEssenceId;
      }
    } else if (type === 'qualificationDetail') {
      url = '/qualifications/details/export/' + essenceId;
    } else if (type === 'dataUsageHistory') {
      url = '/sim_usage_histories/export/excel';
    } else if (type === 'dataUsageHistoryDetails') {
      url = `/sim_usage_histories/${essenceId}/export`;
    } else if (type === 'sim_usages_details') {
      url = `/sim_usages/${essenceId}/export`;
    } else if (type === 'tems') {
      url = `/telecom/exports/expenses`;
    } else if (type === 'dataUsageHistoryCP') {
      url = `/panel/customer/sim_usage_histories/export/excel`;
    } else if (type === 'ordersCp') {
      url = `/panel/customer/orders/export/excel`;
    } else if (type === 'dataUsageHistoryDetailsCP') {
      url = `/panel/customer/sim_usage_histories/${essenceId}/export`;
    } else if (type === 'sim_usages_CP') {
      url = `/panel/customer/sim_usages/export`;
    } else if (type === 'sim_usages_details_cp') {
      url = `/panel/customer/sim_usages/${essenceId}/export`;
    } else if (type === 'qualificationCustomerPortal') {
      url = `/panel/customer/qualification-export`;
    } else if (type === 'cancellationCustomerPortal') {
      url = '/panel/customer/cancellations/export';
    } else if (type === 'runResults') {
      url = '/reports/run/export/excel/' + essenceId;
    } else if (type === 'customerRunResults') {
      url = '/panel/customer/reports/run/export/excel/' + essenceId;
    }
    return await axios.get(url, { params: finalParams, responseType: 'blob' });
  }

  async getAttachments(
    type: string,
    entity_id: number,
    sortData: string,
    sortDirection: string,
    filterData: attachmentFilter,
    isCustomerPortal: boolean
  ): Promise<AxiosResponse<attachmentItemSchema[]>> {
    const params = {
      type,
      entity_id,
      sort_by: sortData,
      sort_direction: sortDirection,
      'filters[date]': filterData.date,
      'filters[name]': filterData.name,
      'filters[category]': filterData.category,
      'filters[type]': filterData.type
    };
    if (isCustomerPortal) {
      return await axios.get('/panel/customer/attachment/show/detail', { params });
    } else {
      return await axios.get('/attachment/show/detail', { params });
    }
  }

  async deleteAttachment(id: number, customerPortal: boolean): Promise<AxiosResponse> {
    const attachmentUrl = customerPortal ? '/panel/customer/attachment/' : '/attachment/';
    return await axios.delete(attachmentUrl + id);
  }

  async createNewAttachment(
    params: newAttachmentSchema,
    entity_id: number,
    type: string,
    description: string,
    categoryId: number | null,
    customerPortal: boolean | null
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    params.file?.forEach((file: File) => {
      formData.append('file[]', file as Blob);
    });
    formData.append('entity_id', entity_id.toString());
    formData.append('description', description.toString());
    formData.append('type', type.toString());
    if (categoryId !== null) formData.append('category_id', categoryId.toString());
    const attachmentUrl = customerPortal ? '/panel/customer/attachment' : '/attachments';
    return await axios.post(attachmentUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getListOfDepartments(): Promise<AxiosResponse<departmentsResponseSchema[]>> {
    return axios.get('/departments');
  }

  async getRelatedPmReviews(
    entityId: number | null,
    departmentId: number | null
  ): Promise<AxiosResponse<ProjectManagerReviewSampleSchema[]>> {
    return axios.get(`/pm/show/detail?entity_id=${entityId}&department_id=${departmentId}`);
  }

  async createProjectManagerReview(params: ProjectManagerReviewSchema, essenceType: string): Promise<AxiosResponse> {
    if (essenceType === 'visit') {
      return axios.post(`/dispatch/visits/${params.entity_id}/review`, params);
    } else {
      return axios.post('/reviews', params);
    }
  }

  async getComments(params: CommentsParams, useDispatch?: boolean): Promise<AxiosResponse<CommentSchema[]>> {
    if (useDispatch) {
      return axios.get('/dispatch/notes', { params: params });
    } else {
      return axios.get('/comments/show/detail', { params: params });
    }
  }

  async getNotes(entityType: string, entityId: number, portal: string): Promise<AxiosResponse<NoteSchema[]>> {
    if (portal === 'customer') {
      return axios.get(`/panel/customer/notes/${entityId}/${entityType}`);
    } else {
      return axios.get(`/notes/${entityId}/${entityType}`);
    }
  }

  async createNote(data: NoteSchema, portal: string): Promise<AxiosResponse> {
    if (portal === 'customer') {
      return axios.post('/panel/customer/notes', data);
    } else {
      return axios.post('/notes', data);
    }
  }

  async editNote(id: number, data: NoteSchema, portal: string): Promise<AxiosResponse> {
    if (portal === 'customer') {
      return axios.patch(`/panel/customer/notes/${id}`, data);
    } else {
      return axios.patch(`/notes/${id}`, data);
    }
  }

  async createComment(params: CommentSchema, useDispatch: boolean): Promise<AxiosResponse<CommentSchema>> {
    if (useDispatch) {
      return axios.post('/dispatch/notes', params);
    } else {
      return axios.post('/comments', params);
    }
  }

  async updateComment(params: CommentSchema, id: number): Promise<AxiosResponse<CommentSchema>> {
    return axios.put('/comments/' + id, params);
  }

  async updateAttachment(
    params: attachmentCategory,
    id: number,
    isCustomerPortal = false
  ): Promise<AxiosResponse<attachmentItemSchema>> {
    return axios.patch(`${isCustomerPortal ? '/panel/customer/attachment' : '/attachments'}/${id}`, params);
  }

  async essenceDelete(
    essenceType: string,
    essenceId: number,
    essenceIdSecondary?: number,
    essenceTypeSecondary?: string
  ): Promise<AxiosResponse> {
    const isEssenceTypeValid = [
      'qualification',
      'qualification service',
      'location',
      'customer',
      'contact',
      'cp-contact',
      'provider',
      'provider alias',
      'comment',
      'project',
      'procurement template',
      'procurement service',
      'make',
      'model',
      'cancellation',
      'template',
      'plan',
      'cellular request',
      'tem',
      'tem-deposit',
      'tem-funding',
      'job',
      'job-task',
      'job-request-visit',
      'visit-comment',
      'billable-item',
      'dispatch-billing-rate',
      'dispatch-sof',
      'user',
      'cp-user',
      'approve-domain',
      'macro',
      'visit-doc',
      'macro-category',
      'role',
      'permission',
      'inventory',
      'agent',
      'Vendor Technician',
      'Vendor Areas',
      'Vendor',
      'MonitoringSchedule',
      'cp-MonitoringSchedule',
      'alerting-email',
      'monitoring-email',
      'partner',
      'partnerCustomer',
      'partnerUser',
      'cp-client-token',
      'note',
      'cp-note'
    ].includes(essenceType);
    if (!isEssenceTypeValid) throw new Error('Essence type is not valid');
    let url = '';
    switch (essenceType) {
      case 'qualification':
        url = '/qualifications/' + essenceId;
        break;
      case 'qualification service':
        url = '/qualifications/' + essenceId + '/services/' + essenceIdSecondary;
        break;
      case 'location':
        url = '/locations/' + essenceId;
        break;
      case 'customer':
        url = '/customers/' + essenceId;
        break;
      case 'contact':
        url = '/contacts/' + essenceId + '/' + essenceIdSecondary + '/' + essenceTypeSecondary;
        break;
      case 'cp-contact':
        url = `/panel/customer/locations/${essenceIdSecondary}/contacts/${essenceId}`;
        break;
      case 'provider':
        url = '/providers/' + essenceId;
        break;
      case 'provider alias':
        url = '/providers/alias/' + essenceId;
        break;
      case 'comment':
        url = '/comments/' + essenceId;
        break;
      case 'project':
        url = '/customers/' + essenceIdSecondary + '/projects/' + essenceId;
        break;
      case 'procurement template':
        url = '/projects/' + essenceIdSecondary + '/qualificationTemplate/' + essenceId;
        break;
      case 'procurement service':
        url = '/procurement-services/' + essenceId;
        break;
      case 'make':
        url = '/inventory_makes/' + essenceId;
        break;
      case 'model':
        url = '/inventory_models/' + essenceId;
        break;
      case 'cancellation':
        url = '/cancellation/' + essenceId;
        break;
      case 'template':
        url = '/template/' + essenceId;
        break;
      case 'plan':
        url = '/plans/' + essenceId;
        break;
      case 'cellular request':
        url = '/cellular_requests/' + essenceId;
        break;
      case 'tem-funding':
        url = '/tem/funding/' + essenceId;
        break;
      case 'tem':
        url = '/telecom/expenses/' + essenceId;
        break;
      case 'tem-deposit':
        url = '/tem/deposit/' + essenceId;
        break;
      case 'job':
        url = '/dispatch/job/' + essenceId;
        break;
      case 'job-task':
        url = '/fieldnation/tasks/' + essenceId;
        break;
      case 'job-request-visit':
        url = '/visits/' + essenceId;
        break;
      case 'visit-comment':
        url = '/dispatch/notes/' + essenceId;
        break;
      case 'billable-item':
        url = `/dispatch/visit/${essenceIdSecondary}/billableItem/${essenceId}`;
        break;
      case 'dispatch-billing-rate':
        url = `/dispatch/billing-rate/${essenceId}`;
        break;
      case 'dispatch-sof':
        url = `/sof/${essenceId}`;
        break;
      case 'user':
        url = `/customers/${essenceIdSecondary}/users/${essenceId}/delete`;
        break;
      case 'cp-user':
        url = `/panel/customer/users/${essenceId}/delete`;
        break;
      case 'approve-domain':
        url = `/customers/${essenceIdSecondary}/approved_domains/${essenceId}/`;
        break;
      case 'macro':
        url = `/macros/${essenceId}`;
        break;
      case 'macro-category':
        url = `/macros/categories/list/${essenceId}`;
        break;
      case 'visit-doc':
        url = `/dispatch/visits/${essenceIdSecondary}/documents/${essenceId}`;
        break;
      case 'role':
        url = `/roles/${essenceId}`;
        break;
      case 'permission':
        url = `/permission/${essenceId}`;
        break;
      case 'inventory':
        url = `/inventory/${essenceId}`;
        break;
      case 'agent':
        url = `/agents/${essenceId}`;
        break;
      case 'Vendor Technician':
        url = `/fst_vendors/teches/${essenceId}`;
        break;
      case 'Vendor Areas':
        url = `/fst_vendors/areas/${essenceId}`;
        break;
      case 'Vendor':
        url = `/fst_vendors/${essenceId}`;
        break;
      case 'MonitoringSchedule':
        url = `/monitoring_notifications/${essenceId}`;
        break;
      case 'cp-MonitoringSchedule':
        url = `/panel/customer/monitoring_notifications/${essenceId}`;
        break;
      case 'alerting-email':
        url = `/customers/${essenceIdSecondary}/alerts/${essenceId}`;
        break;
      case 'monitoring-email':
        url = `/monitoring/alerting-email/${essenceId}/delete`;
        break;
      case 'partner':
        url = `/partnerships/${essenceId}`;
        break;
      case 'partnerCustomer':
        url = `/partnerships/${essenceId}/customers/${essenceIdSecondary}`;
        break;
      case 'partnerUser':
        url = `/partnerships/users/${essenceId}`;
        break;
      case 'cp-client-token':
        url = `/panel/customer/clients/delete/${essenceId}`;
        break;
      case 'note':
        url = `/notes/${essenceId}`;
        break;
      case 'cp-note':
        url = `/panel/customer/notes/${essenceId}`;
        break;
    }
    return await axios.delete(url);
  }
  async handleGlobalSearch(
    query: string,
    model: string,
    customerPortal: boolean,
    customerId: number | 'all' | undefined
  ): Promise<{ data: GlobalSearchResponse }> {
    const searchUrl = customerPortal ? '/panel/customer/global/search' : '/global/search';
    return axios.get(searchUrl, { params: { query, model, customerId } });
  }
  async searchHandlePageChange(
    model: string,
    page: number,
    query: string,
    customerPortal: boolean
  ): Promise<{ data: GlobalSearchResponse }> {
    const searchUrl = customerPortal ? '/panel/customer/global/search' : '/global/search';
    return axios.get(searchUrl, { params: { query, model, page } });
  }
  async loadSearchableModels(customerPortal: boolean): Promise<AxiosResponse<SearchableModel[]>> {
    const searchUrl = customerPortal ? `/panel/customer/global/searchable-models` : `/global/searchable-models`;
    return axios.get(searchUrl);
  }
}
