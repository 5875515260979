<template>
  <Dropdown
    v-model="input"
    :options="Options"
    :option-label="label"
    :option-value="returnValue"
    :placeholder="placeholder"
    :filter="showFilter"
    filter-placeholder="Search ..."
    empty-message="..."
    :disabled="disabled || readOnly"
    :loading="isLoading"
    :class="[styles, error ? 'p-invalid' : '']"
    :show-clear="!disabled"
  >
    <template #option="slotProps">
      <div class="p-dropdown-car-option">
        <span>{{ convertDisplayOption(slotProps.option[label]) }}</span>
      </div>
    </template>
    <template #dropdownicon>
      <slot name="dropdownicon" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, watch, PropType } from 'vue';
import SelectInputService from '../../service/Staff-Dashboard/SelectInputService';
import useEmitter from '../../composables/useEmitter';
import { capitalize } from '../../utilities/globalHelpers';
import { AxiosError } from 'axios';
import { selectInputOptionSchema } from '../../types/Global';

const emitter = useEmitter();

const props = defineProps({
  value: {
    type: [Number, String],
    required: false,
    default: null
  },
  styles: {
    type: String,
    required: false,
    default: 'w-full'
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: false
  },
  error: {
    type: Boolean,
    required: false,
    default: false
  },
  idPrimary: {
    type: [Number, String] as PropType<number | string | null>,
    required: false,
    default: 0
  },
  additionalId: {
    type: [String, Number],
    required: false,
    default: null
  },
  additionalIds: {
    type: Array,
    required: false,
    default: null
  },
  typePrimary: {
    type: String,
    required: true
  },
  returnValue: {
    type: String,
    required: false,
    default: 'id'
  },
  label: {
    type: String,
    required: false,
    default: 'name'
  },
  placeholder: {
    type: String,
    required: true
  },
  showFilter: {
    type: Boolean,
    required: false,
    default: true
  },
  maxContentLength: {
    type: Number,
    required: false,
    default: 300
  },
  preSetOptions: {
    type: Array as PropType<selectInputOptionSchema[]>,
    required: false,
    default: () => []
  },
  optionsSet: {
    type: Array,
    required: false,
    default: () => []
  },
  shouldCapitalize: {
    type: Boolean,
    required: false,
    default: false
  },
  forceLoadData: {
    type: Boolean,
    required: false,
    default: false
  },
  purgeOnUpdate: {
    type: Boolean,
    required: false,
    default: true
  }
});
const SelectInputServiceItem = ref(new SelectInputService());

const isLoading = ref(false);
const Options = ref<selectInputOptionSchema[]>([]);
const emit = defineEmits(['update:data']);
const input = defineModel<string | number>();

watch([() => props.idPrimary, () => props.additionalId, () => props.additionalIds], () => {
  if (props.purgeOnUpdate) {
    input.value = undefined;
  }
});
const loadOptions = () => {
  isLoading.value = true;
  SelectInputServiceItem.value
    .getInputOptions(
      props.typePrimary,
      props.idPrimary as number | undefined,
      props.additionalId,
      props.additionalIds as number[]
    )
    .then((data) => {
      if (typeof data === 'undefined') {
        return;
      }

      Options.value = data.data;
      if (props.preSetOptions && props.preSetOptions.length) {
        Options.value = props.preSetOptions.concat(Options.value);
      }

      emit('update:data', data?.data);
    })
    .catch((error: AxiosError) => {
      emitter!.emit('toast-axios', error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};
const convertDisplayOption = (name: string) => {
  let convertedDisplayOption =
    name.length > props.maxContentLength ? name.substring(0, props.maxContentLength) + '...' : name;
  if (props.shouldCapitalize) convertedDisplayOption = capitalize(convertedDisplayOption);
  return convertedDisplayOption;
};
const loadOptionsInitial = () => {
  if (!props.disabled || props.forceLoadData) {
    if (props.optionsSet && props.optionsSet.length) {
      Options.value = props.optionsSet as selectInputOptionSchema[];
    } else {
      switch (props.typePrimary) {
        case 'QualificationTerm':
          Options.value = [
            { id: 0, name: 'Month-to-Month' },
            { id: 1, name: '12 Months' },
            { id: 2, name: '24 Months' },
            { id: 3, name: '36 Months' },
            { id: 4, name: '48 Months' }
          ];
          break;
        case 'TableSelectedItem':
          Options.value = [
            { id: 0, name: 'Show All' },
            { id: 1, name: 'Show Only Selected' },
            { id: 2, name: 'Show Only Available' }
          ];
          break;
        case 'MonthsOfTheYear':
          Options.value = [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' }
          ];
          break;
        case 'TemBank':
          Options.value = [
            { id: 1, name: 'Wells Fargo' },
            { id: 2, name: 'BofA' }
          ];
          break;
        case 'PollingEngine':
          Options.value = [
            { id: 1, name: 'ENGINE1' },
            { id: 2, name: 'ENGINE2' },
            { id: 3, name: 'ENGINE3' },
            { id: 4, name: 'ENGINE4' }
          ];
          break;
        case 'AttachmentType':
          Options.value = [
            { id: 1, name: 'xlsx' },
            { id: 2, name: 'jpg' },
            { id: 3, name: 'jpeg' },
            { id: 3, name: 'png' },
            { id: 3, name: 'zip' },
            { id: 3, name: 'rar' },
            { id: 3, name: 'yaml' },
            { id: 4, name: 'pdf' }
          ];
          break;
        case 'UnassignFieldNationProvider':
          Options.value = [
            { id: 7, name: 'Provider cancelled' },
            { id: 8, name: 'Provider unresponsive to buyers requests' },
            { id: 9, name: 'Provider failed to meet deadlines within SOW' },
            { id: 48, name: 'Provider arrived too late after start time' },
            { id: 50, name: 'Provider is just not a good fit' },
            { id: 67, name: 'Provider Sick/Injured or Exposed to COVID' },
            { id: 43, name: 'No Call No Show' }
          ];
          break;
        case 'Interval':
          Options.value = [
            { id: 0, name: 'hourly' },
            { id: 5, name: 'daily' },
            { id: 10, name: 'weekly' },
            { id: 15, name: 'bi-weekly' },
            { id: 20, name: 'monthly' },
            { id: 25, name: 'quarterly' },
            { id: 30, name: 'yearly' }
          ];
          break;
        case 'ScheduleStatus':
          Options.value = [
            { id: 0, name: 'not active' },
            { id: 1, name: 'active' }
          ];
          break;
        case 'ReportType':
          Options.value = [
            { id: 0, name: 'staff' },
            { id: 1, name: 'customer' }
          ];
          break;
        default:
          loadOptions();
      }
    }
  }
};
watch(
  () => [
    () => props.disabled,
    () => props.idPrimary,
    () => props.additionalId,
    () => props.additionalIds,
    () => props.returnValue,
    () => props.optionsSet
  ],
  () => {
    loadOptionsInitial();
  },
  { deep: true }
);
onBeforeMount(() => {
  loadOptionsInitial();
});
</script>
