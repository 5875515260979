<template>
  <div class="cp-content" :class="[isDark ? 'is-dark' : 'is-light', isLargeContent ? 'cp-content-large' : '']">
    <div class="bg-header">
      <div class="w-full flex justify-content-between">
        <GlobalSearch :is-customer-portal="true" />
        <div class="align-content-center mr-5">
          <SelectInput
            v-if="currentCustomer.is_partner"
            v-model="customerPartnerId"
            class="m-w-17"
            type-primary="PartnerCustomersCP"
            placeholder="Select customer ..."
            :options-set="partnerCustomers"
          />
        </div>
      </div>
    </div>
    <router-view v-if="updateView > 0" :key="updateView || $route.fullPath" />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, watch, provide, readonly } from 'vue';
import useEmitter from '../../composables/useEmitter';
import GlobalSearch from '../../components/Multipurpose/GlobalSearch.vue';
import SelectInput from '../../components/Multipurpose/SelectInput.vue';
import { LocalStorageUserType, PartnerCustomerType } from '../../types/Global';
import axios from 'axios';
import { PartnerProviderType } from '../../types/Customer-Dashboard/partner';
import { useRoute } from 'vue-router';
import { detailPagesCP } from '../../composables/usePartner';

const emitter = useEmitter();
const route = useRoute();

const isDark = ref(getCurrentInstance()?.appContext.config.globalProperties.$isDark);

const isLargeContent = ref(false);
const customerPartnerId = ref(0);
const updateView = ref(0);
const currentCustomer = JSON.parse(localStorage.getItem('user') || '{}') as LocalStorageUserType;

const partnerCustomers: PartnerCustomerType[] = [{ id: 0, name: 'All Customers' }];

provide<PartnerProviderType>('partner', {
  selectedPartner: readonly(customerPartnerId),
  is_partner: currentCustomer.is_partner
});

if (currentCustomer.partner?.customers) {
  partnerCustomers.push(...currentCustomer.partner.customers);
}
if (currentCustomer.is_partner) {
  axios.defaults.headers['cis-partner'] = 'all';
}
updateView.value++;

watch(customerPartnerId, (newValue) => {
  axios.defaults.headers['cis-partner'] = newValue === 0 || !newValue ? 'all' : newValue;
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('dt-')) {
      localStorage.removeItem(key);
    }
  });
  if (detailPagesCP.includes(route.name as string)) return;
  updateView.value++;
});

emitter!.on<'toggle-large-content'>('toggle-large-content', (status: boolean) => {
  isLargeContent.value = status;
});
</script>

<style scoped lang="scss">
.cp-content {
  position: fixed;
  left: 287px;
  top: 0;
  min-height: 100vh;
  width: calc(100% - 287px);
  height: 200px;
  overflow: auto;
  padding-bottom: 115px;
}

.cp-content-large {
  width: calc(100% - 110px);
  left: 110px;
}

.is-light {
  background: #fafafa;
  .bg-header {
    background-color: #ffffff;
    border-bottom: #eeeeee 1px solid;
  }
}
.is-dark {
  background: #121212;
  .bg-header {
    background-color: #212121;
    border-bottom: #3b3b3b 1px solid;
  }
}
@media screen and (max-width: 967px) {
  .cp-content {
    top: 64px;
    left: 0;
    width: 100%;
  }
}
</style>
