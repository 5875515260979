import BaseLayout from '../layouts/Staff-Dashboard/BaseLayout.vue';
import { logout } from '../utilities/logout';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import axios from 'axios';
import { AzureUserType, BreadcrumbTitle } from '../types/Global';

const loadView = (view: string) => {
  return () => import(`../views/Staff-Dashboard/${view}.vue`);
};
export const StaffDashboardRouter = [
  {
    path: '/setToken',
    name: 'SetToken',
    component: loadView('SetToken'),
    meta: { title: 'Redirecting ...', ignoreHistory: true }
  },
  {
    redirect: { name: 'Dashboard' },
    path: '/staff',
    component: BaseLayout,
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: loadView('Dashboard'),
        meta: { title: 'Dashboard', ignoreHistory: true }
      },
      {
        name: 'CustomersList',
        path: 'customers',
        component: loadView('CustomersList'),
        meta: { title: 'Customers List' }
      },
      {
        name: 'PartnershipManagement',
        path: 'partnerships',
        component: loadView('PartnershipManagement'),
        meta: { title: 'Partnership Management' }
      },
      {
        name: 'PartnerDetail',
        path: 'partnerships/:id',
        component: loadView('PartnerCustomersList'),
        meta: { title: 'Partnership Customer' }
      },
      {
        name: 'PartnershipUsersPage',
        path: 'partnerships/:id/users',
        component: loadView('PartnerUserList'),
        meta: { title: 'Partnership Customer' }
      },
      {
        name: 'InventoryList',
        path: 'inventory',
        component: loadView('InventoryList'),
        meta: { title: 'Inventory List' }
      },
      {
        name: 'InventoryDetails',
        path: 'inventory-details/:inventoryId',
        component: loadView('InventoryDetail'),
        meta: { title: 'Inventory Details' }
      },
      {
        name: 'Make',
        path: 'make',
        component: loadView('MakeList'),
        meta: { title: 'Make/Model' }
      },
      {
        name: 'CustomerCenter',
        path: 'customer-center/:userId',
        component: loadView('CustomerCenter'),
        meta: { title: 'Customer Center' }
      },
      {
        name: 'Qualifications',
        path: 'qualifications',
        component: loadView('Qualifications'),
        meta: { title: 'Qualifications' }
      },
      {
        name: 'QualificationDetail',
        path: 'qualification-detail/:id',
        component: loadView('QualificationDetail'),
        meta: { title: 'Qualification Results' }
      },
      {
        name: 'AssignedLocations',
        path: 'assigned-locations/:id/:customerName/:qualificationName',
        component: loadView('AssignedLocations'),
        meta: { title: 'Assigned Locations' }
      },
      {
        name: 'Orders',
        path: 'orders',
        component: loadView('Orders'),
        meta: { title: 'Orders' }
      },
      {
        name: 'AllOrders',
        path: 'orders?filter=All Orders',
        component: loadView('Orders'),
        meta: { title: 'Orders' }
      },
      {
        name: 'ApprovedOrders',
        path: 'orders?filter=Approved To Order',
        component: loadView('Orders'),
        meta: { title: 'Orders' }
      },
      {
        name: 'PendingOrders',
        path: 'orders?filter=Pending Install',
        component: loadView('Orders'),
        meta: { title: 'Orders' }
      },
      {
        name: 'FollowUpOrders',
        path: 'orders?filter=Order Follow Up',
        component: loadView('Orders'),
        meta: { title: 'Orders' }
      },
      {
        name: 'OrderDetail',
        path: 'order-detail/:id',
        component: loadView('OrderDetail'),
        meta: { title: 'Order Detail' }
      },
      {
        name: 'Cancellations',
        path: 'cancellations',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'OpenCancellations',
        path: 'cancellations?filter=open',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'AccountingCancellations',
        path: 'cancellations?filter=accounting',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'PmReviewCancellations',
        path: 'cancellations?filter=pm_review',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'AllCancellations',
        path: 'cancellations?filter=all',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'ClosedCancellations',
        path: 'cancellations?filter=closed',
        component: loadView('Cancellations'),
        meta: { title: 'Cancellations' }
      },
      {
        name: 'CancellationDetail',
        path: 'cancellation-detail/:id',
        component: loadView('CancellationDetail'),
        meta: { title: 'Cancellation Detail' }
      },
      {
        name: 'ProcurementServices',
        path: 'procurement-services',
        component: loadView('ProcurementServices'),
        meta: { title: 'Procurement Services' }
      },
      {
        name: 'ProcurementServiceDetail',
        path: 'procurement-services-detail/:id',
        component: loadView('ProcurementServiceDetail'),
        meta: { title: 'Procurement Services Detail' }
      },
      {
        name: 'Templates',
        path: 'templates',
        component: loadView('Templates'),
        meta: { title: 'Templates' }
      },
      {
        name: 'Batches',
        path: 'batches',
        component: loadView('Batches'),
        meta: { title: 'Batches' }
      },
      {
        name: 'BatchDetails',
        path: 'batch-details/:batchId',
        component: loadView('BatchDetail'),
        meta: { title: 'Batch Details View' }
      },
      {
        name: 'Addresses',
        path: 'addresses',
        component: loadView('Addresses'),
        meta: { title: 'Addresses' }
      },
      {
        name: 'AddressesDetails',
        path: 'addresses-details/:addressId',
        component: loadView('AddressDetail'),
        meta: { title: 'Address Details View' }
      },
      {
        name: 'ProviderManagement',
        path: 'provider-management',
        component: loadView('ProviderManagement'),
        meta: { title: 'Provider Management' }
      },
      {
        name: 'DataUsage',
        path: 'data-usage',
        component: loadView('DataUsage'),
        meta: { title: 'Data Usage' }
      },
      {
        name: 'DataUsageDetails',
        path: 'data-usage-details/:id',
        component: loadView('DataUsageDetails'),
        meta: { title: 'Data Usage Details' }
      },
      {
        name: 'DataUsageHistory',
        path: 'data-usage-history',
        component: loadView('DataUsageHistory'),
        meta: { title: 'Data Usage History' }
      },
      {
        name: 'DataUsageHistoryDetails',
        path: 'data-usage-history-details/:id',
        component: loadView('DataUsageHistoryDetail'),
        meta: { title: 'Data Usage History Details' }
      },
      {
        name: 'DataUsagePlans',
        path: 'data-usage-plans',
        component: loadView('DataUsagePlans'),
        meta: { title: 'Data Usage Plans' }
      },
      {
        name: 'DataUsagePlansDetail',
        path: 'data-usage-plans-detail/:id',
        component: loadView('DataUsagePlansDetail'),
        meta: { title: 'Data Usage Plans Detail' }
      },
      {
        name: 'CradlePoint',
        path: 'cradle-point',
        component: loadView('CradlePoint'),
        meta: { title: 'Cradle Point' }
      },
      {
        name: 'Verizon',
        path: 'verizon',
        component: loadView('Verizon'),
        meta: { title: 'Verizon' }
      },
      {
        name: 'ChoiceIot',
        path: 'ChoiceIot',
        component: loadView('ChoiceIot'),
        meta: { title: 'Choice IoT' }
      },
      {
        name: 'DataUsageRequestsAddEdit',
        path: 'data-usage-requests/:id',
        component: loadView('DataUsageRequestsAddEdit'),
        meta: { title: 'Data Usage Request' }
      },
      {
        name: 'DataUsageRequestsList',
        path: 'data-usage-requests-list',
        component: loadView('DataUsageRequestsList'),
        meta: { title: 'Data Usage Requests List' }
      },
      {
        name: 'DataUsageCustomerPlanMapper',
        path: 'data-usage-customer-plan-mapper',
        component: loadView('DataUsageCustomerPlanMapper'),
        meta: { title: 'Customer Plan Mapper' }
      },
      {
        name: 'Development',
        path: 'development',
        component: loadView('DevelopmentPage'),
        meta: { title: 'Development' }
      },
      {
        name: 'Tem',
        path: 'tem',
        component: loadView('Tem'),
        meta: { title: 'TEM' }
      },
      {
        name: 'TemAccount',
        path: 'tem/account/:vendorId',
        component: loadView('TemAccount'),
        meta: { title: 'TEM Account Bills' }
      },
      {
        name: 'TemFunding',
        path: 'tem-funding',
        component: loadView('TemFunding'),
        meta: { title: 'TEM Funding' }
      },
      {
        name: 'TemBills',
        path: 'tem-bills',
        component: loadView('TemBills'),
        meta: { title: 'TEM Bills' }
      },
      {
        name: 'TemBillMfa',
        path: 'bills/mfa',
        component: loadView('TemBillMfa'),
        meta: { title: 'TEM Bill MFA' }
      },
      {
        name: 'AddEditJobRequest',
        path: 'job-request/:id',
        component: loadView('AddEditJobRequest'),
        meta: { title: 'Create Job Request' }
      },
      {
        name: 'JobRequests',
        path: 'job-request',
        component: loadView('JobRequest'),
        meta: { title: 'Job Request Queue' }
      },
      {
        name: 'JobBookingQueue',
        path: 'job-booking-queue',
        component: loadView('JobBookingQueue'),
        meta: { title: 'Job Booking Queue' }
      },
      {
        name: 'AddEditJobTemplate',
        path: 'job-template/:id',
        component: loadView('AddEditJobRequest'),
        meta: { title: 'Create Job Request Template' }
      },
      {
        name: 'JobTemplates',
        path: 'job-template',
        component: loadView('JobRequest'),
        meta: { title: 'Job Request Templates' }
      },
      {
        name: 'WorkOrderReview',
        path: 'work-order-review',
        component: loadView('WorkOrderReviewQueue'),
        meta: { title: 'Work Order Review Queue' }
      },
      {
        name: 'MyVisit',
        path: 'my-visits',
        component: loadView('MyVisit'),
        meta: { title: 'My Visits' }
      },
      {
        name: 'LogisticsQueue',
        path: 'logistics-queue',
        component: loadView('LogisticsQueue'),
        meta: { title: 'Logistics Queue' }
      },
      {
        name: 'EVMBillingReview',
        path: 'evm-billing-review',
        component: loadView('EVMBillingReview'),
        meta: { title: 'External Visit Management Billing Review' }
      },
      {
        name: 'SOFManager',
        path: 'sof',
        component: loadView('SOFManager'),
        meta: { title: 'Schedule of Fees' }
      },
      {
        name: 'FstAccounting',
        path: 'fst-accounting',
        component: loadView('FstAccounting'),
        meta: { title: 'FST Accounting' }
      },
      {
        name: 'ProcessVisitBillableItem',
        path: 'fst-accounting/:visitId/process',
        component: loadView('ProcessVisitBillableItem'),
        meta: { title: 'Process Visit Billable Item' }
      },
      {
        name: 'OpenVisit',
        path: 'open-visits',
        component: loadView('OpenVisit'),
        meta: { title: 'Open Job Visits' }
      },
      {
        name: 'VisitManagementDashboard',
        path: 'visit-management',
        component: loadView('VisitManagementDashboard'),
        meta: { title: 'Visit Management' }
      },
      {
        name: 'VisitManagementDetail',
        path: 'visit-management/:id',
        component: loadView('VisitManagementDetailPage'),
        meta: { title: 'Visit Management Details' }
      },
      {
        name: 'FullScreenVisitManagementDashboard',
        path: 'visit-management?full_screen=true',
        component: loadView('VisitManagementDashboard'),
        meta: { title: 'Visit Management' }
      },
      {
        name: 'Tickets',
        path: 'tickets',
        component: loadView('Tac'),
        meta: { title: 'Support Tickets' }
      },
      {
        name: 'MyTickets',
        path: 'my-tickets',
        component: loadView('Tac'),
        meta: { title: 'My Tickets' }
      },
      {
        name: 'TicketDetail',
        path: 'tickets/:id',
        component: loadView('TicketDetail'),
        meta: { title: 'Support Ticket' }
      },
      {
        name: 'OutageManager',
        path: 'outage-manager',
        component: loadView('OutageManger'),
        meta: { title: 'OutageManager' }
      },
      {
        name: 'CallQueue',
        path: 'call-queue',
        component: loadView('CallQueue'),
        meta: { title: 'Call Queue' }
      },
      {
        name: 'PingTool',
        path: 'ping-tool',
        component: loadView('PingTool'),
        meta: { title: 'Ping Tool' }
      },
      {
        name: 'Monitoring',
        path: 'monitoring',
        component: loadView('Monitoring'),
        meta: { title: 'Monitoring' }
      },
      {
        name: 'MonitoringLocationDetail',
        path: 'monitoring/location-detail/:id',
        component: loadView('MonitoringLocationDetail'),
        meta: { title: 'Monitoring Location Detail' }
      },
      {
        name: 'MonitoringDeviceDetail',
        path: 'monitoring/detail/:id',
        component: loadView('MonitoringDeviceDetail'),
        meta: { title: 'Monitoring Device Detail' }
      },
      {
        name: 'AddTicket',
        path: 'tickets/new',
        component: loadView('CreateTac'),
        meta: { title: 'Add new TAC' }
      },
      {
        name: 'PMReviews',
        path: 'pm-reviews',
        component: loadView('PmReviews'),
        meta: { title: 'PM Review' }
      },
      {
        name: 'PMReviewsHistory',
        path: 'pm-reviews-history',
        component: loadView('PmReviewsHistory'),
        meta: { title: 'PM Review History' }
      },
      {
        name: 'Macros',
        path: 'macros',
        component: loadView('Macros'),
        meta: { title: 'Quick Insert' }
      },
      {
        name: 'AuditLog',
        path: 'audit-logs',
        component: loadView('AuditLog'),
        meta: { title: 'Audit Log' }
      },
      {
        name: 'RoleManagement',
        path: 'role-management',
        component: loadView('RoleManagement'),
        meta: { title: 'Assign Role To Groups' }
      },
      {
        name: 'RoleManagementList',
        path: 'manage-roles',
        component: loadView('RoleManagementList'),
        meta: { title: 'Manage Roles' }
      },
      {
        name: 'GroupManagementList',
        path: 'manage-groups',
        component: loadView('GroupManagementList'),
        meta: { title: 'Manage Groups' }
      },
      {
        name: 'PermissionManagement',
        path: 'permission-management',
        component: loadView('PermissionManagement'),
        meta: { title: 'User Permission List' }
      },
      {
        name: 'StaffProfile',
        path: 'profile',
        component: loadView('Profile'),
        meta: { title: 'Profile' }
      },
      {
        name: 'AgentManagement',
        path: 'agent-management',
        component: loadView('AgentManagement'),
        meta: { title: 'Agent Management' }
      },
      {
        name: 'LocationDetail',
        path: 'location-detail/:id',
        component: loadView('LocationDetail'),
        meta: { title: 'Location Detail' }
      },
      {
        name: 'VendorManagement',
        path: 'vendor-management',
        component: loadView('VendorManagement'),
        meta: { title: 'Vendor Management' }
      },
      {
        name: 'ReportList',
        path: 'reports',
        component: loadView('Reports'),
        meta: { title: 'Report List' }
      },
      {
        name: 'ReportScheduleList',
        path: 'reports/schedules/:reportId',
        component: loadView('ReportSchedules'),
        meta: { title: 'ReportSchedule List' }
      },
      {
        name: 'ReportRunResult',
        path: 'reports/run/:reportId',
        component: loadView('ReportRunResult'),
        meta: { title: 'Report Run Result' }
      }
    ]
  }
];
const setUser = async (toPath: string) => {
  if (toPath.includes('/staff')) {
    if (localStorage.getItem('user') && localStorage.getItem('isAuthenticated')) return;
    await axios
      .get<AzureUserType>('/azure/user')
      .then((data) => {
        if (data.status !== 200) throw data;
        const userObjectToSave = {
          id: data.data.id,
          firstName: data.data.givenName || '',
          lastName: data.data.surname || '',
          fullName: data.data.displayName || '',
          jobTitle: data.data.jobTitle || '',
          email: data.data.mail || '',
          department: data.data.department || '',
          avatar: data.data.avatar || '',
          roles: data.data.roles || '',
          permissions: data.data.permissions || '',
          extension_id: data.data.extension_id || ''
        };
        localStorage.setItem('user', JSON.stringify(userObjectToSave));
        if (data.data.is_dark) {
          localStorage.setItem('isDark', 'true');
        } else {
          localStorage.removeItem('isDark');
        }
      })
      .catch(() => {
        logout().catch(() => {});
      });
  }
};

const convertToReadableString = (inputString: string): string => {
  const words = inputString.split(/(?=[A-Z])/);
  const result = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return result.join(' ');
};

const generateBreadcrumbTitle = (pathData: RouteLocationNormalized): string => {
  let result = convertToReadableString(pathData.name as string);
  let paramString = '';
  let queryString = '';
  if (Object.keys(pathData.params).length) {
    paramString = Object.entries(pathData.params)
      .map(([key, value]) => `${convertToReadableString(key)} #${convertToReadableString(value.toString())}`)
      .join(' => ');
  }
  if (Object.keys(pathData.query).length) {
    queryString = Object.entries(pathData.query)
      .map(
        ([key, value]) => `${convertToReadableString(key)}: ${value ? convertToReadableString(value.toString()) : '-'}`
      )
      .join(' => ');
  }
  if (paramString && paramString.length) result = result + ' => ' + paramString;
  if (queryString && queryString.length) result = result + ' => ' + queryString;
  return result;
};

const generateHistoryItem = (pathData: RouteLocationNormalized): BreadcrumbTitle => {
  return {
    name:
      pathData.meta && pathData.meta.title
        ? (pathData.meta.title as string)
        : convertToReadableString(pathData.name as string),
    fullPath: pathData.fullPath,
    fullName: generateBreadcrumbTitle(pathData)
  };
};

const saveHistory = (toPath: RouteLocationNormalized, fromPath: RouteLocationNormalized) => {
  if (
    !fromPath ||
    !fromPath.name ||
    (fromPath.meta && fromPath.meta.ignoreHistory) ||
    (toPath.meta && toPath.meta.ignoreHistory)
  )
    return sessionStorage.removeItem('staff-history');
  const tempHistory: BreadcrumbTitle[] = [];
  const getHistory: BreadcrumbTitle[] = JSON.parse(
    sessionStorage.getItem('staff-history') as string
  ) as BreadcrumbTitle[];
  if (!getHistory || !getHistory.length) {
    tempHistory.push(
      {
        name: 'Dashboard',
        fullPath: '/staff/dashboard',
        fullName: 'Dashboard'
      },
      generateHistoryItem(fromPath),
      generateHistoryItem(toPath)
    );
  } else {
    tempHistory.push(
      {
        name: 'Dashboard',
        fullPath: '/staff/dashboard',
        fullName: 'Dashboard'
      },
      getHistory[2],
      generateHistoryItem(toPath)
    );
  }
  sessionStorage.setItem('staff-history', JSON.stringify(tempHistory));
};
export const beforeEachStaffDashboard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const toRoute: string = to.name ? to.name.toString() : '';
  const toPath: string = to.fullPath ? to.fullPath.toString().toLowerCase() : '';
  await setUser(toPath);
  if (toRoute === 'Login' && localStorage.getItem('isAuthenticated')) next({ name: 'Dashboard' });
  else if (toPath.startsWith('/staff') && !localStorage.getItem('isAuthenticated')) {
    await logout(false);
  }
  saveHistory(to, from);
  next();
};
