import axios, { AxiosError } from 'axios';
const isPROD: boolean = import.meta.env.PROD;
import { logout } from './logout';

const mainAxios = () => {
  axios.defaults.baseURL = <string>import.meta.env.VITE_BASE_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';
  if (isPROD) axios.defaults.timeout = 100000;
  axios.defaults.withCredentials = true;
  axios.defaults.withXSRFToken = true;
};
axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response && (error.response.status === 401 || error.response.status === 419)) await logout();

    if (error.response?.status === 403 && error.config?.url?.startsWith('/panel/customer')) {
      location.replace('/access-denied');
      return;
    }

    return Promise.reject(error);
  }
);
axios.interceptors.request.use((config) => {
  if (config.url?.startsWith('/panel/customer')) {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return config;
});
export { mainAxios };
