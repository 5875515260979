<template>
  <div
    v-if="[0, null, undefined].includes(selectedPartner) && customers.length > 0"
    class="field col-12"
    :class="{ 'md:col-6': !fullWidth }"
  >
    <label v-if="showLabel" for="customer" class="block">Customer</label>
    <SelectInput
      id="customer"
      v-model="selectedCustomer"
      type-primary="PartnerCustomerCP"
      placeholder="Select Customer ..."
      return-value="id"
      class="w-full"
      :disabled="disabled"
      :class="{ 'p-invalid': formErrorMessage }"
      :options-set="customers"
      force-load-data
    />
    <p v-if="formErrorMessage && showError" class="error-color">{{ formErrorMessage }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { LocalStorageUserType, PartnerCustomerType } from '../../../types/Global';
import SelectInput from '../../../components/Multipurpose/SelectInput.vue';
import { usePartner } from '../../../composables/usePartner';

type Props = {
  formErrorMessage?: string;
  fullWidth?: boolean;
  showError?: boolean;
  allCustomers?: boolean;
  allCustomerId?: 'all';
  disabled?: boolean;
  showLabel?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  formErrorMessage: '',
  fullWidth: false,
  showError: true,
  allCustomers: false,
  allCustomerId: 'all',
  disabled: false,
  showLabel: true
});

const { is_partner, selectedPartner } = usePartner();

const currentCustomer = JSON.parse(localStorage.getItem('user') || '{}') as LocalStorageUserType;

const selectedCustomer = defineModel<number | 'all'>();

const customers = ref<PartnerCustomerType[]>([]);

if (props.allCustomers && is_partner) {
  customers.value.push({ id: props.allCustomerId, name: 'All Customers' });
}
if (currentCustomer.partner?.customers) {
  customers.value.push(...currentCustomer.partner.customers);
}
</script>
