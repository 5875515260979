<template>
  <div class="flex justify-content-start border-b-4 top-navbar__global-search">
    <span class="p-input-icon-left ml-5 global-search-input">
      <i class="pi pi-search" />
      <InputText placeholder="Search ..." class="cursor-pointer" @click="visible = true" />
    </span>

    <Dialog
      v-model:visible="visible"
      modal
      :pt="{
        mask: { style: 'backdrop-filter: blur(2px)' }
      }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      maximizable
      closable
      dismissable-mask
      class="dialog__global-search"
    >
      <template #header>
        <div>
          <PartnerCustomersSelect
            v-if="isCustomerPortal"
            v-model="customerId"
            class="w-12"
            :show-label="false"
            all-customers
          />
        </div>
      </template>
      <div class="global-search">
        <ScrollPanel :pt="{ root: { class: 'global-search-scroll' } }">
          <TabView v-model:active-index="activeTab" class="global-search-tab-view" scrollable>
            <TabPanel v-for="tab in tabs" :key="tab.key" :header="tab.name">
              <div class="search-container mt-2">
                <InputText v-model="searchQuery" placeholder="Search..." class="w-full mb-4" @input="debounceSearch" />

                <div v-if="loading" class="text-center">
                  <ProgressSpinner />
                </div>

                <div v-else>
                  <template v-if="activeTab === 0">
                    <div v-for="(section, sectionKey) in searchResults" :key="sectionKey" class="mb-6">
                      <h3 v-if="section.total != 0" class="text-xl font-bold mb-3">
                        {{ getTabName(sectionKey) }}
                      </h3>
                      <SearchResultSection
                        v-if="section.total != 0"
                        :results="section.data"
                        :total="section.total"
                        :current-page="section.currentPage"
                        :last-page="section.lastPage"
                        :section-key="sectionKey"
                        :search-query="searchQuery"
                      />
                    </div>
                    <p
                      v-if="
                        (searchQuery != '' && !section && props.isCustomerPortal) ||
                        (searchResults[currentSection] && searchResults[currentSection].total == 0)
                      "
                      class="text-center"
                    >
                      no item found for your search
                    </p>
                  </template>
                  <template v-else-if="searchResults[currentSection] && searchResults[currentSection].total != 0">
                    <SearchResultSection
                      :results="searchResults[currentSection].data"
                      :total="searchResults[currentSection].total"
                      :current-page="searchResults[currentSection].currentPage"
                      :last-page="searchResults[currentSection].lastPage"
                      :section-key="currentSection"
                      :search-query="searchQuery"
                      :is-customer-portal="isCustomerPortal"
                    />
                  </template>
                  <template
                    v-else-if="
                      !section &&
                      searchQuery != '' &&
                      searchResults[currentSection] &&
                      searchResults[currentSection].total == 0
                    "
                  >
                    <p class="text-center">no item found for your search</p>
                  </template>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </ScrollPanel>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { debounce } from '../../utilities/globalHelpers';
import SearchResultSection from './GlobalSearchResultSection.vue';
import { AxiosError } from 'axios';
import useEmitter from '../../composables/useEmitter';
import { GlobalSearchResponse, SearchableModel, PaginationPayload } from '../../types/Global';
import GlobalService from '../../service/Staff-Dashboard/GlobalService';
import ScrollPanel from 'primevue/scrollpanel';
import PartnerCustomersSelect from '../../components/Customer-Dashboard/Partner/PartnerCustomersSelect.vue';
import { usePartner } from '../../composables/usePartner';

const visible = ref(false);
const customerId = ref<number | 'all' | undefined>('all');
const searchQuery = ref('');
const activeTab = ref(0);
const loading = ref(false);
const searchResults = ref<GlobalSearchResponse>({});
const searchableModels = ref<SearchableModel[]>([]);
const emitter = useEmitter();
const GlobalServiceItem = ref(new GlobalService());

const props = defineProps({
  isCustomerPortal: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { is_partner, selectedPartner } = usePartner();

const tabs = computed(() => {
  return [{ key: 'all', name: 'All' }, ...searchableModels.value];
});

const currentSection = computed(() => {
  return activeTab.value === 0 ? 'all' : tabs.value[activeTab.value].key;
});

const getTabName = (key: string) => {
  const model = searchableModels.value.find((m) => m.key === key);
  return model ? model.name : key.charAt(0).toUpperCase() + key.slice(1);
};

const loadSearchableModels = () => {
  loading.value = true;
  GlobalServiceItem.value
    .loadSearchableModels(props.isCustomerPortal)
    .then((data) => {
      searchableModels.value = data.data;
    })
    .catch((error: AxiosError) => {
      emitter!.emit('toast-axios', error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const search = () => {
  if (!searchQuery.value) {
    searchResults.value = {};
    return;
  }

  if (props.isCustomerPortal) {
    if (is_partner! && selectedPartner.value) {
      customerId.value = selectedPartner.value;
    }
    if (!is_partner) {
      customerId.value = undefined;
    }
  } else {
    customerId.value = undefined;
  }
  loading.value = true;
  GlobalServiceItem.value
    .handleGlobalSearch(searchQuery.value, currentSection.value, props.isCustomerPortal, customerId.value)
    .then((data) => {
      searchResults.value = data.data;
    })
    .catch((error: AxiosError) => {
      emitter!.emit('toast-axios', error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const debounceSearch = debounce(() => search(), 500);

const handlePageChange = (currentSection: string, page: number) => {
  loading.value = true;
  GlobalServiceItem.value
    .searchHandlePageChange(currentSection, page, searchQuery.value, props.isCustomerPortal)
    .then((response) => {
      if (!searchResults.value) {
        searchResults.value = {};
      }
      searchResults.value[currentSection] = response.data?.[currentSection] ?? {};
    })
    .catch((error: AxiosError) => {
      emitter!.emit('toast-axios', error);
    })
    .finally(() => {
      loading.value = false;
    });
};

emitter!.on('global-search-page-change', (data) => {
  const payload = data as PaginationPayload;
  handlePageChange(payload.section, payload.page);
});

emitter!.on<'open-global-search'>('open-global-search', () => {
  visible.value = true;
});

watch(visible, (newValue) => {
  if (!newValue) {
    searchQuery.value = '';
    activeTab.value = 0;
    searchResults.value = {};
    loading.value = false;
  }
});

onMounted(() => {
  void loadSearchableModels();
});
</script>

<style scoped lang="scss">
i.input-icon__global-search {
  top: 50%;
  transform: translateY(-50%);
  padding: 0 8px;
}
.search-option :deep(em) {
  font-style: normal;
  font-weight: bold;
}
@media screen and (max-width: 967px) {
  .top-navbar__global-search {
    display: none !important;
  }
}
.w-\[230px\] {
  max-width: 230px;
}
.global-search-input {
  padding: 17px 32px 17px 0;
}
:global(.global-search .p-tabview .p-tabview-nav) {
  background: inherit !important;
}
:global(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
  background: inherit !important;
}
:deep(.p-tabview-panels) {
  padding: 0 !important;
}

:deep(.p-dialog-header) {
  padding: 1.5rem !important;
}
:deep(.p-tabview .p-tabview-nav-btn.p-link) {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  color: #ffffff;
  width: 3rem !important;
  height: 3rem !important;
}
:deep(.global-search-scroll) {
  width: 100%;
  height: 600px;
}
:deep(.global-search-tab-view) {
  padding: 1.5rem !important;
}
</style>
