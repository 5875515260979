<template>
  <div class="search-result-section">
    <div>
      <ScrollPanel class="global-search-result-section-scroll-bar">
        <Listbox :options="results" option-label="name" class="w-full md:w-56" :list-style="{ height: 'auto' }">
          <template #option="slotProps">
            <div class="flex-col">
              <div class="items-center mb-2">
                <a :href="createRoute(slotProps.option.model)" target="_blank" class="link-to-page">
                  <span class="font-medium text-sm">
                    {{ formatFieldName(getPrimaryMatchedFieldName(slotProps.option)) }} :
                  </span>
                  <span v-html="getPrimaryMatchedFieldValue(slotProps.option)"></span>
                </a>
              </div>
              <div v-if="hasSecondaryMatches(slotProps.option)" class="text-sm text-gray-500">
                <div v-for="(match, field) in getSecondaryMatches(slotProps.option)" :key="field" class="mb-1">
                  <span class="font-medium">{{ formatFieldName(field) }} : </span>
                  <span v-html="match.matchedValue"></span>
                </div>
              </div>
            </div>
          </template>
        </Listbox>
      </ScrollPanel>

      <Paginator
        v-if="lastPage > 1"
        :rows="10"
        :total-records="total"
        :first="(currentPage - 1) * 10"
        @page="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { SectionKey, MatchedFields } from '../../types/Global';
import Listbox from 'primevue/listbox';
import Paginator from 'primevue/paginator';
import useEmitter from '../../composables/useEmitter';
const emitter = useEmitter();
import { useRouter } from 'vue-router';
import ScrollPanel from 'primevue/scrollpanel';

const router = useRouter();
const props = defineProps({
  results: {
    type: Array as PropType<unknown[]>,
    required: true
  },
  total: {
    type: Number,
    required: true
  },
  currentPage: {
    type: Number,
    required: true
  },
  lastPage: {
    type: Number,
    required: true
  },
  sectionKey: {
    type: String,
    required: true
  },
  searchQuery: {
    type: String,
    required: true
  },
  isCustomerPortal: {
    type: Boolean,
    required: false,
    default: false
  }
});

const handlePageChange = (event: { page: number }) => {
  emitter!.emit('global-search-page-change', {
    section: props.sectionKey,
    page: event.page + 1
  });
};

const getPrimaryMatchedFieldName = (option: Record<string, string>) => {
  return Object.keys(option.matchedFields)[0];
};

const getPrimaryMatchedFieldValue = (option: { matchedFields: MatchedFields }): string | undefined => {
  const firstMatchedField = Object.entries(option.matchedFields)[0];
  return firstMatchedField?.[1]?.matchedValue;
};

const hasSecondaryMatches = (option: Record<string, string>) => {
  return option.matchedFields && Object.keys(option.matchedFields).length > 1;
};

const getSecondaryMatches = (option: Record<string, string>) => {
  if (!option.matchedFields) return {};
  const [, ...secondaryMatches] = Object.entries(option.matchedFields);

  return Object.fromEntries(secondaryMatches);
};

const formatFieldName = (fieldName: string) => {
  return fieldName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const createRoute = (item: Record<string, string>) => {
  const routes: Partial<Record<SectionKey, () => string | null>> = {
    orders: () =>
      router.resolve({ name: props.isCustomerPortal ? 'OrderDetail_CP' : 'OrderDetail', params: { id: item.id } }).href,
    tickets: () =>
      router.resolve({ name: props.isCustomerPortal ? 'TicketDetail_CP' : 'TicketDetail', params: { id: item.id } })
        .href,
    locations: () =>
      router.resolve({ name: props.isCustomerPortal ? 'LocationDetail_CP' : 'LocationDetail', params: { id: item.id } })
        .href,
    qualifications: () =>
      router.resolve({
        name: props.isCustomerPortal ? 'QualificationDetail_CP' : 'QualificationDetail',
        params: { id: item.id }
      }).href,
    // contacts: () => item.name,
    // projects: () => item.name,
    customers: () => router.resolve({ name: 'CustomerCenter', params: { userId: item.id } }).href
    // users: () => item.name
  };

  if (!props.isCustomerPortal) {
    routes.inventories = () => router.resolve({ name: 'InventoryDetails', params: { inventoryId: item.id } }).href;
  }

  return routes[props.sectionKey as SectionKey]?.() ?? null;
};
</script>

<style scoped>
:deep(em) {
  background-color: rgba(244, 183, 63, 0.1254901961);
  color: #f4b73f !important;
  padding: 0.2em;
  border-radius: 2px;
}
:deep(.link-to-page) {
  text-decoration: none !important;
  color: #8890a0;
}
:deep(.global-search-result-section-scroll-bar) {
  width: 100%;
  height: 250px !important;
}
</style>
